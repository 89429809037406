.filters {
  text-align: left;
  padding-top: 2em;
  padding-bottom: 2em;
}

.reset-filters-btn {
  margin-left: 1.5em;
}

.form-label {
  font-weight: bold;
}