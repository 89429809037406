.pool-name {
    .pair-text {
        flex-grow: 0;
        max-width: unset;
    }
    img {
        height: 2em;
    }

    img:last-child {
        margin-right: 0.25em;
    }
}

.stacked-logo:not(:first-child) {
    margin-left: -1.25em;
}

.react-bootstrap-table table {
    table-layout: auto;
}

.table td, .table th {
    vertical-align: middle;
}

td div {
    align-items: center;
}

.logo-col {
    flex-grow: 0;
}