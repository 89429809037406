html, body, #root {
  height: 100%;
  font-family: "Helvetica";
}

.full-height {
  height: 100%;
}

.App {
  min-width: 960px;
  max-width: 100%;
  text-align: center;
}

.silent {
  font-size: 0.9em;
  opacity: 0.7;
}

.apy-fiat-text {
  margin-left: 1em;
}